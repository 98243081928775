import React, { useEffect, useState, Fragment } from "react";
import { usePopup } from "../context";
import { TimerText, TimerWrapper } from "./bank";
import { ReversedTransaction } from "./views/reversed-transaction";

export const Timer = ({ onStop, time }: { onStop: any; time: number }) => {
  const [minutesSpan, setMinutesSpan] = useState<any>(null);
  const [secondsSpan, setSecondsSpan] = useState<any>(null);
  const { isConfirmClicked, isReversal } = usePopup();
  let timeinterval: any;

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date() as any);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  }

  function initializeClock(endtime) {
    function updateClock() {
      const t = getTimeRemaining(endtime);

      setMinutesSpan(("0" + t.minutes).slice(-2));
      setSecondsSpan(("0" + t.seconds).slice(-2));

      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }

    updateClock();
    timeinterval = setInterval(updateClock, 1000);
  }

  const CALCULATED_NUMBER_TO_GIVE_10_MINS = 15 * 24 * 60 * 27.8 * time;

  const deadline = new Date(
    Date.parse(new Date() as unknown as string) +
      CALCULATED_NUMBER_TO_GIVE_10_MINS
  );

  useEffect(() => {
    initializeClock(deadline);
    return () => {
      clearInterval(timeinterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (secondsSpan == "57" && minutesSpan == "00") {
      clearInterval(timeinterval);
      onStop();
    }
  }, [minutesSpan, secondsSpan, onStop, timeinterval]);

  return (
    <Fragment>
      {isReversal ? (
        <ReversedTransaction />
      ) : (
        <TimerWrapper>
          {!isConfirmClicked && (
            <TimerText>
              Order expires in{" "}
              <strong style={{ color: "#12B76A" }}>
                {minutesSpan}:{secondsSpan}
              </strong>{" "}
              minutes
            </TimerText>
          )}
          {isConfirmClicked && Number(minutesSpan) > 4 && (
            <span style={{ fontSize: "12px", textAlign: "center" }}>
              Please wait for{" "}
              <strong style={{ color: "red" }}>
                {minutesSpan}:{secondsSpan}
              </strong>{" "}
              minutes
            </span>
          )}
          {isConfirmClicked &&
            Number(minutesSpan) > 0 &&
            Number(minutesSpan) <= 4 && (
              <span style={{ fontSize: "12px", textAlign: "center" }}>
                Your transaction is taking too long, kindly exercise patience
                while we confirm your payment. You have{" "}
                <strong style={{ color: "red" }}>
                  {minutesSpan}:{secondsSpan}
                </strong>{" "}
                minutes left to complete this transaction.
              </span>
            )}
        </TimerWrapper>
      )}
    </Fragment>
  );
};
