import React, { useContext } from "react";

// PLEASE DO NOT MODIFY PK BELOW.....
export const pk = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAolGZqnlhFBkpfGxqVdsV
GfY6/ZqLhSuri1pdoFTfzKELs3+rf9H7sDBvRdPqXB0a5lB0ueLjpBTlncnUiYm5
7kIYHEYkJnpG0VZY1hkk3AN/Ny1hWFRUe0OkQTEqPUIeycGh6ZO/hgiYdmAJqLvB
WCpfIC7G2DS1Y/6esgi/W73XZ7RbkdUY2JPfOmzGpsDtqzKeQCg9p2cOr9ir9jpc
IBzd3W9X1kHZasFSH7YLRNJSEJ91GQWQNHNUxuSoT+Ht8OXEYbff8bNGEfsrSQCa
bz69CKCm3fxgqx7tR4GzZHb/8Yi+3Y2AJT118EU4EF5qaPEQmZzhzWqeQ4S59P+j
/wIDAQAB
-----END PUBLIC KEY-----
`;
// PLEASE DO NOT MODIFY PK ABOVE.....

export type PopupContextType = {
  banks: [] | any;
  setBanks: (banks: any) => void;
  setSelectedBank?: (bank: any) => void;
  selectedBank?: any;
  pinValue?: string;
  setPinValue?: (pinValue: string) => void;

  customerName?: string;
  setCustomerName?: (pinValue: string) => void;

  paymentReference?: string;
  setPaymentReference?: (pinValue: string) => void;

  isBankSet?: boolean;
  setIsBankSet?: (isBankSet: boolean) => void;

  options?: any;
  setOptions?: (option: any) => void;

  merchantDetails?: any;
  setMerchantDetails?: (merchantDetails: any) => void;

  isSuccess?: boolean;
  setIsSuccess?: (isSuccess: boolean) => void;

  
  isReversal?: boolean;
  setIsReversal?: (isReversal: boolean) => void;

  isDowntime?: any;
  setIsDowntime?: (isDowntime: any) => void;
  

  position?: number;
  setPosition: (position: number) => void;

  currentIndex?: number;
  setCurrentIndex?: (currentIndex: number) => void;

  isTimeLapsed?: boolean;
  setIsTimeLapsed?: (isTimeLapsed: boolean) => void;
  
  isCountDownExpired?: boolean;
  setIsCountDownExpired?: (isTimeLapsed: boolean) => void;

  isModalOpen?: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;

  isConfirmClicked?: boolean;
  setIsConfirmClicked?: (isConfirmClicked: boolean) => void;

  showCharge: boolean;
  setShowCharge: (showCharge: boolean) => void;

  cardCharge?: any;
  setCardCharge?: (cardCharge: any) => void;

  paymentPayload?: any;
  setPaymentPayload?: (paymentPayload: any) => void;

  cardError?: boolean;
  setCardError?: (cardErr: any) => void;

  isFailed?: boolean;
  setIsFailed?: (isFailed: boolean) => void;

  isRefUsed?: boolean;
  setIsRefUsed?: (isRefUsed: boolean) => void;

  globalError?: string;
  setGlobalError?: (error: string) => void;

  triggerInitiate: boolean;
  setTriggerInitiate?: (trigger: boolean) => void;

  loginSuccessFul?: boolean;
  setLoginSuccessFul?: (loginSuccessFul: boolean) => void;

  isTransactionStarted?: boolean;
  setIsTransactionStarted?: (isTransactionStarted: boolean) => void;
};

// React.createContext<PopupContext | null>(null);

export const PopupContext = React.createContext<PopupContextType>({
  banks: [],
  paymentPayload: {},
  setPaymentPayload: () => {},
  setBanks: () => {},
  selectedBank: {},
  setSelectedBank: () => {},
  pinValue: "",
  setPinValue: () => {},
  customerName: "",
  setCustomerName: () => {},
  paymentReference: "",
  setPaymentReference: () => {},
  isBankSet: false,
  setIsBankSet: () => {},
  options: {},
  setOptions: () => {},
  merchantDetails: {},
  setMerchantDetails: () => {},
  isSuccess: false,
  setIsSuccess: () => {},
  isReversal: false,
  setIsReversal: () => {},
  isDowntime: {},
  setIsDowntime: () => {},
  position: 0,
  setPosition: () => {},
  isTimeLapsed: false,
  setIsTimeLapsed: () => {},
  isCountDownExpired: false,
  setIsCountDownExpired: () => {},
  currentIndex: 0,
  setCurrentIndex: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  isConfirmClicked: false,
  setIsConfirmClicked: () => {},
  showCharge: false,
  setShowCharge: () => {},
  cardCharge: 10,
  setCardCharge: () => {},
  cardError: false,
  setCardError: () => {},
  isFailed: false,
  setIsFailed: () => {},
  isRefUsed: false,
  setIsRefUsed: () => {},
  globalError: "",
  setGlobalError: () => {},

  triggerInitiate: false,
  setTriggerInitiate: () => {},

  loginSuccessFul: false,
  setLoginSuccessFul: () => {},

  isTransactionStarted: false,
  setIsTransactionStarted: () => {},
});

export const channelMap = {
  bank: 0,
  ussd: 1,
  card: 2,
  scan: 3,
};

export const usePopup = () => useContext(PopupContext);

export const PopupContextWrapper = ({ children }: any) => {
  const [banks, setBanks] = React.useState([]);
  const [selectedBank, setSelectedBank] = React.useState({});
  const [pinValue, setPinValue] = React.useState("");
  const [isBankSet, setIsBankSet] = React.useState(false);
  const [options, setOptions] = React.useState<any>({});
  const [merchantDetails, setMerchantDetails] = React.useState<any>({});
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [position, setPosition] = React.useState<number>(0);
  const [isTimeLapsed, setIsTimeLapsed] = React.useState(false);
  const [isCountDownExpired, setIsCountDownExpired] = React.useState(false);

  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = React.useState(false);
  const [showCharge, setShowCharge] = React.useState(false);
  const [cardCharge, setCardCharge] = React.useState(10);
  const [paymentPayload, setPaymentPayload] = React.useState<any>({});
  const [cardError, setCardError] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);
  const [isRefUsed, setIsRefUsed] = React.useState(false);
  const [globalError, setGlobalError] = React.useState("");
  const [triggerInitiate, setTriggerInitiate] = React.useState(false);
  const [loginSuccessFul, setLoginSuccessFul] = React.useState(false);
  const [isTransactionStarted, setIsTransactionStarted] = React.useState(false);
  const [isReversal, setIsReversal] = React.useState(false);
  const [isDowntime, setIsDowntime] = React.useState({});
  const [customerName, setCustomerName] = React.useState("");
  const [paymentReference, setPaymentReference] = React.useState("");


  return (
    <PopupContext.Provider
      value={{
        banks,
        setBanks,
        selectedBank,
        setSelectedBank,
        pinValue, 
        setPinValue,
        customerName,
        setCustomerName,
        setPaymentReference,
        paymentReference,
        isBankSet,
        setIsBankSet,
        options,
        setOptions,
        merchantDetails,
        setMerchantDetails,
        isSuccess,
        setIsSuccess,
        isReversal,
        setIsReversal,
        isDowntime,
        setIsDowntime,
        position,
        setPosition,
        isTimeLapsed,
        setIsTimeLapsed,
        isCountDownExpired,
        setIsCountDownExpired,
        currentIndex,
        setCurrentIndex,
        isModalOpen,
        setIsModalOpen,
        isConfirmClicked,
        setIsConfirmClicked,
        showCharge,
        setShowCharge,
        cardCharge,
        setCardCharge,
        paymentPayload,
        setPaymentPayload,
        cardError,
        setCardError,
        isFailed,
        setIsFailed,
        isRefUsed,
        setIsRefUsed,
        globalError,
        setGlobalError,
        triggerInitiate,
        setTriggerInitiate,
        loginSuccessFul,
        setLoginSuccessFul,
        isTransactionStarted,
        setIsTransactionStarted,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
