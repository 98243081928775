import { Fragment, useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import styled from "styled-components";
import { formatN, getConfirmPayment, getTerminatePayment, initiatePayment } from "../api/service";
import { usePopup } from "../context";
import {
  useCalculatedAmount,
  useExit,
  useSentMoney,
  useSocket,
} from "../hooks";
import { BankContainer, BankText, ChargeWrapper, PaymentAccountWarning, UnstyledButton } from "./bank";
import { ChargesSection } from "./charge";
import { AppButton } from "./components/app-button";
import { Spacer } from "./components/spacer";
import { Image } from "./nav";
import { Timer } from "./timer";
import copy from "../assets/copy.svg";
import { FailedTransaction } from "./views/failed-transaction";
import { SelectBankSection } from "./views/select-bank";
import { SuccessfulTransaction } from "./views/sucessful-transaction";
import { ProcessingTransaction } from "./views/processing-transaction";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReversedTransaction } from "./views/reversed-transaction";
import { ExpiredBankTransaction } from "./views/expired-bank-transaction";
import { BankTextProps } from "../dropin/types";
import { CountDownExpiration } from "./views/count-down-expiration";

export const USSDSection = () => {
  const [isError, setIsError] = useState(false);
  const [intervalId, setIntervalId] = useState<any>(null);
  const {
    options,
    merchantDetails: { account_number },
    pinValue,
    isSuccess,
    setIsTimeLapsed,
    isTimeLapsed,
    selectedBank,
    setIsBankSet,
    setMerchantDetails,
    setIsConfirmClicked,
    showCharge,
    isBankSet,
    isFailed,
    isRefUsed,
    isConfirmClicked,
    isReversal,
    setPaymentPayload,
    setIsSuccess,
    setPaymentReference,
    paymentReference,
    setIsTransactionStarted,
    isCountDownExpired,
    setIsCountDownExpired,
  } = usePopup();

  const { loading, onClick } = useSentMoney();
  const onUssdConfrimClick = () => {
    setIsConfirmClicked!(true);
    onClick();
  };
  const { AmountToShow, calculatedCharge } = useCalculatedAmount();
  useSocket("ussd");
  const { qrid = "", pay_category = "", amount } = options;

  let info = {
    live_mode: false,
    amount: AmountToShow,
    customer_name: options?.name ?? "",
    customer_account_number: pinValue!.slice(-4),
    customer_email: options.email,
    customer_phone: options.customer_phone ?? "",
    transactionref: paymentReference === "" ? options.transactionref: paymentReference,
    payment_option: "ussd",
    bankCode: selectedBank[0]?.code,
    original_amount: amount,
  };

  let addedOptionArray = { pay_category, qrid };
  Object.keys(addedOptionArray).forEach((option) => {
    if (addedOptionArray[option] !== "") {
      info[option] = addedOptionArray[option];
    }
  });
  useEffect(() => {
    const fetchData = async () => {
      const res = await initiatePayment(options.key, info);
      setPaymentReference!("");
      setMerchantDetails!(res.data.data);
      if (!res.data.data.account_number) {
        setIsError(true);
      }
      setIsBankSet!(true);
      let newintervalID = setInterval(async () => {
        try {
          const res = await getConfirmPayment(
            options.transactionref,
            options.key
          );
          setPaymentPayload!(res?.data?.data);
          if (res?.data?.data?.paymentstatus === "paid") {
            setIsSuccess!(true);
          }
        } catch (error: any) {
          console.error(error);
          Store.addNotification({
            insert: "top",
            container: "top-right",
            message: 'Error: "The transaction has not been confirmed"',
            type: "danger",
            dismiss: {
              duration: 3000,
              pauseOnHover: true,
            },
          });
          // setLoading(false);
        }
      }, 30000);
      setIntervalId(newintervalID);
    };

    isBankSet && !isSuccess && !showCharge && fetchData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBankSet, isSuccess]);

  const onTimeStopped = async () => {
    setIsCountDownExpired!(true);
    try {
      await getTerminatePayment(options.transactionref, options.key);
    } catch (error: any) {
      console.log(error);
    }
  };

  const _TEXT = `*${selectedBank[0]?.ussd}*000*5037+${account_number}+${formatN(
    AmountToShow
  )}#`;

  const onCopyToClipboard = () => {
    Store.addNotification({
      insert: "top",
      container: "top-right",
      message: "Copied to clipboard",
      type: "success",
      dismiss: {
        duration: 2000,
      },
    });
  };


  const changeOriginBank = ()=>{
    setIsBankSet!(false);
    setIsTransactionStarted!(false);
    setPaymentReference!(info.transactionref);
    clearInterval(intervalId);
  }

  const renderInfo = () => (
    <Fragment>
      {!isConfirmClicked ? (
        <Fragment>
          <StandardAppWidth>
            <BankText style={{ width: "100%" }}>
              Dial the code below to complete this transactiion. Include all
              symbols in the code (e.g. * + and #)
            </BankText>
          </StandardAppWidth>
          <Spacer height={20} />
          <CopyUSSDDetailsWrapper
            style={{ width: "100%", cursor: "pointer" }}
          >
            <div>
              {isError ? (
                "USSD not available for payment at this time. Please choose another payment option."
              ) : (
                <a
                  href={`tel:${_TEXT}`}
                  style={{ textDecoration: "none", color: "#1C1C1E" }}
                >
                  {_TEXT}
                </a>
              )}
            </div>
            <>
              <CopyToClipboard text={_TEXT} onCopy={onCopyToClipboard}>
                <Image src={copy} />
              </CopyToClipboard>
            </>
          </CopyUSSDDetailsWrapper>
          <Spacer height={35} />
          <ChargeWrapper>Processing Fee {calculatedCharge}</ChargeWrapper>
          <Spacer height={20} />
    
          {!isError && <Timer onStop={() => onTimeStopped()} time={2} />}
          <Spacer height={36} />
          <StandardAppWidth >
                <PaymentAccountWarning>
                  <ul style={{paddingLeft:"10px"}}>
                    <li>
                      <div>
                      The sender account details from the previous screen will be used to verify your payment. <span style={{fontWeight: "bold"}}>Picked the wrong bank?</span> {" "}
                        <span
                          style={{
                            color: "blue",
                            fontStyle: "italic",
                            cursor: "pointer",
                          }}
                          onClick={() => changeOriginBank()}
                        >
                          Change Bank
                        </span>
                      </div>
                    </li>
                    <li>Send <span style={{fontWeight: "bold"}}>EXACT</span> amount as stated above, otherwise your payment will be reversed.</li>
                    <li>If your payment comes in <span style={{fontWeight: "bold"}}>AFTER</span> the countdown below has expired, it will be reversed.</li>
                  </ul>
                </PaymentAccountWarning>
                </StandardAppWidth>
                <Spacer height={36} />
          {!isError && (
            <AppButton
              type="grayBtn"
              disabled={false}
              onClick={() => onUssdConfrimClick()}
              isBusy={loading}
              name="I've sent the money"
            />
          )}
        </Fragment>
      ) : (
        <ProcessingTransaction />
      )}
    </Fragment>
  );

  const ErrorTxt = `Please check your account balance. If you have been debited kindly reach out to ${
    options.customer_service_channel || ""
  } and try again.`;

  const renderHelper = () => {
    if (isRefUsed) {
      return <GenericRefErrorWrapper />;
    }
    if (!isBankSet) {
      return <SelectBankSection ussdBanks={true} />;
    } else {
      if (isSuccess) return <SuccessfulTransaction />;
      if(isReversal) return <ReversedTransaction />;
      if (isTimeLapsed) return <ExpiredBankTransaction />;
      if (isCountDownExpired) return <CountDownExpiration />;
      if (showCharge) return <ChargesSection payment_option="ussd" />;
      if (isFailed) return <FailedTransaction errorMessage={ErrorTxt} />;
      return renderInfo();
    }
  };

  return <BankContainer>{renderHelper()}</BankContainer>;
};

export const ErrorWrapper = ({
  isError,
  type,
}: {
  isError: boolean;
  type: string;
}) => {
  return (
    <Fragment>
      {isError && (
        <USSDDetailsWrapper style={{ width: "100%" }}>
          <H3>
            {type} is not available for payment at this time. Please choose
            another payment option.
          </H3>
        </USSDDetailsWrapper>
      )}
    </Fragment>
  );
};

export const GenericRefErrorWrapper = () => {
  const ExitPopup = useExit();
  const { globalError } = usePopup();
  return (
    <Fragment>
      <USSDDetailsWrapper style={{ width: "100%" }}>
        <H3>{globalError}</H3>
      </USSDDetailsWrapper>
      <Spacer height={15} />
      <UnstyledButton
        style={{
          paddingTop: "2em",
          fontWeight: "bold",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => { window.location.reload()}}
        className="mobile-only"
      >
        Close
      </UnstyledButton>
    </Fragment>
  );
};

export const H3 = styled.h3`
  text-align: center;
  letter-spacing: -0.3px;
  color: #000000;
  font-size: 18px;

  a {
    color: #000000;
    text-decoration: none;
  }

  a:hover {
    color: #000000;
    font-size: 18px;
  }

  a:focus {
    color: #000000;
    font-size: 18px;
  }
`;

export const USSDDetailsWrapper = styled.div<BankTextProps>`
  background: rgba(247, 143, 33, 0.05);
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.3em;
`;

export const CopyUSSDDetailsWrapper = styled.div<BankTextProps>`
  background: #f8f8fc;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 1.3em;
  height: 63px;
  max-width: 282px;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #1c1c1e;

  @media (max-width: 600px) {
    max-width: 355px;
  }
`;

export const StandardAppWidth = styled.div<BankTextProps>`
  width: 282px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 355px !important;
  }

  @media (max-width: 370px) {
    width: 282px !important;
  }

  @media (max-width: 311px) {
    width: 252px !important;
  }
`;
