import { useCallback, useEffect, useState } from "react";
import { EventName, postEvent } from "../../../dropin/events";
import { BankContainer, BankText, UnstyledButton } from "../../bank";
import { Spacer } from "../../components/spacer";

import { Timer } from "../../timer";
import { H3, StandardAppWidth } from "../../ussd";
import { usePopup } from "../../../context";
import styled from "styled-components";
import { Image } from "../../nav";
import succesIcon from "../../../assets/transaction_done_Icon.svg";
import recievedIcon from "../../../assets/transaction_received _icon.svg";
import LinearProgressWithTimer from "../../components/LinearProgressWithTimer";
import { BankTextProps } from "../../../dropin/types";

export const ProcessingTransaction = () => {
  const [minutesSpan, setMinutesSpan] = useState<any>(null);
  const [secondsSpan, setSecondsSpan] = useState<any>(null);
  // const { isConfirmClicked, setIsTimeLapsed } = usePopup();
  const [showAccountNo, setshowAccountNo] = useState(false);


  const {
    merchantDetails: { account_name, account_number, bank_name, ...others },
    // setMerchantDetails,
    // options,
    // pinValue,
    // isSuccess,
    setIsTimeLapsed,
    // isTimeLapsed,
    // setIsBankSet,
    // setIsConfirmClicked,
    // showCharge,
    // isBankSet,
    // selectedBank,
    // isFailed,
    // isRefUsed,
  } = usePopup();
  let timeinterval: any;

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date() as any);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  }

  function initializeClock(endtime: any) {
    function updateClock() {
      const t = getTimeRemaining(endtime);

      setMinutesSpan(("0" + t.minutes).slice(-2));
      setSecondsSpan(("0" + t.seconds).slice(-2));

      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }

    updateClock();
    timeinterval = setInterval(updateClock, 1000);
  }

  const CALCULATED_NUMBER_TO_GIVE_20_MINS = 30 * 24 * 60 * 27.8;

  const deadline = new Date(
    Date.parse(new Date() as unknown as string) +
      CALCULATED_NUMBER_TO_GIVE_20_MINS
  );

  const onStop = useCallback(() => {
    alert("stopped");
    
  }, []);

  useEffect(() => {
    initializeClock(deadline);
    return () => {
      clearInterval(timeinterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (secondsSpan === "00" && minutesSpan === "00") {
      clearInterval(timeinterval);
      onStop();
    }
  }, [minutesSpan, secondsSpan, onStop, timeinterval]);

  const durationInMinutes = 20;


  return (
    <BankContainer>
      <StandardAppWidth>
        <Spacer height={20} />
        <ProcessingText>
          Your transfer is on the way. It can take up to a minute to confirm
        </ProcessingText>
        <Spacer height={15} />
        <ProcessActivityWrapper>
          <Image src={succesIcon} />
          <div style={{ width: "151px", marginTop: "11px", color:"green" }}>
          <LinearProgressWithTimer durationInMinutes={durationInMinutes} />
          </div>
          <div>
            <Image src={recievedIcon} /> <ReceivedIcon />
          </div>
        </ProcessActivityWrapper>
        <Spacer height={10} />
        <Timer onStop={() => setIsTimeLapsed!(true)} time={2}/>
        <Spacer height={30} />
        <ProcessingAccountText onClick={() => setshowAccountNo(!showAccountNo)}>
          Show beneficiary account number 
        </ProcessingAccountText>
        <Spacer height={15} />
        {showAccountNo && (
          <ProcessingAccountText>{account_number}</ProcessingAccountText>
        )}
      </StandardAppWidth>
    </BankContainer>
  );
};

export const ReceivedIcon = styled.div<BankTextProps>`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #636366;
  letter-spacing: 0.01em;
`;

export const ProcessActivityWrapper = styled.div<BankTextProps>`
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
`;

export const ProcessingText = styled.div<BankTextProps>`
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.01em;
  width: 90%;
`;

export const ProcessingAccountText = styled.div<BankTextProps>`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #8e8e93;
  cursor: pointer;
`;
